<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ViewerPage',

  components: {
    Modal: () => import('@/components/general/Modal')
  },

  data () {
    return {
      title: '',
      view: ''
    }
  },

  computed: {
    ...mapState([ 'fetching' ])
  },

  created () {
    this.setFetching(true)

    Promise.all([
      this.getSystemEmailInfo(),
      this.getSystemEmailView()
    ]).finally(() => {
      this.setFetching(false)
    })
  },

  methods: {
    ...mapActions([
      'setFetching',
      'attemptManageSystemEmail',
      'attemptViewSystemEmail'
    ]),

    closeModal () {
      this.$router.push({ name: 'notifications.emails' })
    },

    getSystemEmailInfo () {
      this.attemptManageSystemEmail({
        idSystemEmail: this.$route.params.id,
        replaceTitle: true
      }).then((response) => {
        this.title = response.title
      }).catch(err => {
        console.error(err)

        this.setFeedback({ message: this.$t('notifications.systemEmails.load:fetch.error') })
        this.$router.push(this.backUrl)
      })
    },

    getSystemEmailView () {
      this.attemptViewSystemEmail(this.$route.params.id).then((response) => {
        this.view = response.data
      }).catch(err => {
        console.error(err)

        this.setFeedback({ message: this.$t('notifications.systemEmails.load:fetch.error') })
        this.$router.push(this.backUrl)
      })
    }
  }
}
</script>

<template>
  <Modal
    :active="!fetching"
    :cancel-label="$t('global:back')"
    @close="closeModal"
  >
    <div class="modal-form modal-view-email">
      <span class="modal-subtitle">{{ $t('notifications.view:modal.subtitle') }}</span>
      <h2 class="modal-title">
        {{ $t('notifications.view:modal.title') }}
      </h2>
      <h3 class="email-title">
        {{ title }}
      </h3>
      <div class="email-view">
        <div
          class="email-view-body"
          v-html="view"
        />
      </div>
    </div>
  </Modal>
</template>

<style lang="scss" src="../assets/scss/Viewer.scss"></style>
